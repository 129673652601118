body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: absolute;
  top: 15%;
  width: 100%;
}

.btn-floating {
  position: absolute;
  top: 5%;
  width: 100%;
  text-align: center;
  font-size: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cd-title {
  /*display:table-cell;*/
  color: white;
  background-color: gray;
  width: 100%;
  text-align: center;
  /*box-shadow: 0 0 0 1px black inset;*/
  font-size: 2.5vh;
  /*float: left*/
}
.cd-target {
  /*display:table-cell;*/
  color: white;
  background-color: gray;
  width: 100%;
  text-align: center;
  /*box-shadow: 0 0 0 1px black inset;*/
  font-size: 2vh;
  /*float: right*/
}

.cd {
  /*display:table-cell;*/
  color: white;
  background-color: black;
  text-align: center;
  font-size: 3.5vh;
  width: 100%;
}

.foot {
  border: 7px white;
  height: 1vw;
  width: 100%;
}